<template>
  <div class="start" :class="{ zoom: isLoginAnimationPlay }">
    <div class="start-video">
      <video src="@/assets/video/bg.mp4" autoplay loop muted></video>
    </div>
    <div class="start-ball" v-if="isShowBall"></div>
    <!-- <div class="start-center-logo">
      <img src="@/assets/img/center-logo.svg" alt="">
    </div> -->
    <transition name="slide-down">
      <div class="start-head" v-if="isShowTitle">
        <span
          class="start-title title-1"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="-5000"
          ><span class="space">за</span> предел<span class="accent-letter">а</span
          >ми</span
        >
        <br />
        <span
          class="start-title title-2"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="-5000"
          >во<span class="accent-letter">о</span>бражения</span
        >
        <br />
        <p
          class="start-text"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="-5000"
        >
          Первое биспецифическое антитело в терапии ФЛ<small>1,2</small>
        </p>
        <!-- <p
          class="start-note"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="-5000"
        >
          1. https://www.roche.ru/media/press-relizy/2023/med-cor-2023-12-07a
          дата доступа 30.01.2024
        </p>
        <p
          class="start-note"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="-5000"
        >
          2.
          https://www.gene.com/media/press-releases/14978/2022-12-22/fda-approves-genentechs-lunsumio-a-first
          дата доступа 30.01.2024
        </p> -->
      </div>
    </transition>

    <button
      type="button"
      class="start-button"
      @click="open"
      :disabled="isDataLoading"
      data-aos="fade-down"
      data-aos-delay="700"
    >
      Начать
    </button>
    <div class="start-element start-element-1"></div>
    <div class="start-element start-element-2"></div>
    <div class="start-element start-element-3 parallax" value="2">
      <div class="start-element-3-item"></div>
    </div>
    <div
      class="information"
      data-aos="fade-up"
      data-aos-delay="500"
      data-aos-offset="-5000"
    >
      <div class="info-button light" @click="openInfo"></div>
      <transition name="slide-fade-info">
        <div class="info-content small" v-if="infoBlock">
          <div class="info-content-close" @click="closeInfo"></div>
          <div class="info-content-row">
            <ul>
              <li>
                <a
                  href="https://www.roche.ru/media/press-relizy/2023/med-cor-2023-12-07a"
                  target="_blank"
                  >https://www.roche.ru/media/press-relizy/2023/med-cor-2023-12-07a</a
                >
                дата доступа 30.01.2024
              </li>
              <li>
                <a
                  href="https://www.gene.com/media/press-releases/14978/2022-12-22/fda-approves-genentechs-lunsumio-a-first"
                  target="_blank"
                  >https://www.gene.com/media/press-releases/14978/2022-12-22/fda-approves-genentechs-lunsumio-a-first</a
                >
                дата доступа 30.01.2024
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
    <p
      class="start-note right"
      data-aos="fade-up"
      data-aos-delay="500"
      data-aos-offset="-5000"
    >
      M-RU-00015465 февраль 2024
    </p>
    <transition name="slide-fade">
      <LoginForm
        v-if="loginForm"
        @close="close"
        @continue="onContinue"
        v-model="userName"
      />
    </transition>
    <div class="cookies" v-if="isShowCookies">
      <div class="cookies-text">
        <h2>Использование cookies</h2>
        <p>
          Данный сайт использует файлы cookies и другие технологии («файлы cookies»),
          которые позволяют получать информацию о Вашем пользовательском опыте с целью
          оптимизации работы веб-сайта. Помимо обязательных файлов cookies, веб-сайт может
          использовать файлы cookies, предназначенные (1), чтобы узнать, как работает
          веб-сайт, включая межсайтовую статистику (2), чтобы предоставить Вам
          дополнительные функции (3), чтобы предоставить Вам возможность взаимодействия
          через социальные сети и (4) чтобы осуществлять таргетинг и маркетинг. Нажимая
          «Принять все» Вы даете согласие на использование файлов cookies, обработку
          данных, в том числе информации о Вашем браузере и IP-адресе, и предоставление их
          третьим лицам, как описано в нашей

          <a href="https://www.roche.ru/cookies" target="_blank"
            >Политике о файлах cookies</a
          >
          и
          <a href="https://www.roche.ru/konfidencialnost" target="_blank"
            >конфиденциальности</a
          >
          .
        </p>
      </div>
      <div class="cookies-buttons">
        <button
          class="cookies-button cookies-button--cancel"
          @click="isShowCookies = false"
        >
          отклонить
        </button>
        <button class="cookies-button" @click="isShowCookies = false">принять</button>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import { useLogin } from "@/composables/use-login";
import AOS from "aos";
import { ymEvent } from "@/utils/ymEvent";
export default {
  name: "StartPage",

  components: {
    LoginForm,
  },
  setup() {
    const { isUnknownUser, getUserData } = useLogin();

    return {
      isUnknownUser,
      getUserData,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.isShowCookies = true;
    }, 4000);
    document.addEventListener("mousemove", this.parallax);
    AOS.init();
    this.isDataLoading = true;
    this.userName = await this.getUserData();
    this.isDataLoading = false;
  },

  data() {
    return {
      isLoginAnimationPlay: false,
      isDataLoading: false,
      loginForm: false,
      isShowTitle: true,
      isShowBall: false,
      isShowCookies: false,
      userName: "",
      infoBlock: false,
    };
  },

  methods: {
    parallax() {
      document.querySelectorAll(".parallax").forEach((shift) => {
        const position = shift.getAttribute("value");
        const x = (window.innerWidth - event.pageX * position) / 90;
        const y = (window.innerHeight - event.pageY * position) / 90;

        shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    },
    open() {
      if (this.isUnknownUser) {
        this.onContinue();
        ymEvent("startbuttonfirstscreen");
        return;
      } else {
        ymEvent("startbuttonfirstscreen");
      }

      this.loginForm = true;
      this.$emit("open");
    },
    close() {
      this.loginForm = false;
      this.$emit("close");
    },
    openInfo() {
      this.infoBlock = true;
      this.$emit("openInfo");
      ymEvent("infoandsourcesbutton");
    },
    closeInfo() {
      this.infoBlock = false;
      this.$emit("closeInfo");
    },
    async onContinue() {
      this.isLoginAnimationPlay = true;
      this.isShowTitle = false;
      this.isShowBall = true;
      this.close();
      await this.$store.dispatch("initRoom");
      this.$emit("logged-in");
      this.isLoginAnimationPlay = false;
      this.isShowTitle = true;
      this.isShowBall = false;
      this.isLoginAnimationPlay = false;
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(220px);
  opacity: 0;
}
.slide-down-enter-active {
  transition: all 0.3s ease-out;
}

.slide-down-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(220px);
  opacity: 0;
}

.slide-fade-info-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-info-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-info-enter-from,
.slide-fade-info-leave-to {
  transform: translateY(20px) translateX(-20px);
  opacity: 0;
}
</style>
