<template>
  <ChapterView>
    <template v-slot:title><Chapter_4_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-16.png" alt=""></template>
    <template v-slot:name>Свешникова <br>Юлия Валентиновна</template>
    <template v-slot:regalia><div class="long-text">ГАУЗ СО «Свердловская областная <br>клиническая больница № 1», Екатеринбург</div></template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_4_Title from '@/components/Chapter_4_Title.vue'

export default {
  name: 'Chapter_4',
  components: {
    ChapterView,
    Chapter_4_Title,
  },
}
</script>
