<template>
  <ChapterView>
    <template v-slot:title><Chapter_2_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-15.png" alt=""></template>
    <template v-slot:name>Антонов <br><span class="long-name">Андрей Александрович</span></template>
    <template v-slot:regalia>ГБУЗ "Салехардская окружная <br>клиническая больница", Салехард</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_2_Title from '@/components/Chapter_2_Title.vue'

export default {
  name: 'Chapter_2',
  components: {
    ChapterView,
    Chapter_2_Title,
  },
}
</script>
