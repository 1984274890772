<script lang="ts" setup>
import { nextTick, onMounted, watch } from "vue";
import { computed, ref } from "vue";
import { VideoParticipantExpose } from "@/components/video-conference/types/video-partisipant-expose";
import VideoFadeTransition from "@/components/video-conference/VideoFadeTransition.vue";
import HandIcon from "@/components/video-conference/icons/HandIcon.vue";
import { VideoParticipantProps } from "./types/video-participant-props";
import { Track } from "livekit-client";

const props = defineProps<VideoParticipantProps>();

const activeReaction = ref("");
const reactionTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

const isHandUp = ref(false);

const setReaction = async (message: string) => {
  if (reactionTimeout.value) {
    clearTimeout(reactionTimeout.value);
    activeReaction.value = "";
    await nextTick();
  }

  activeReaction.value = message;
  reactionTimeout.value = setTimeout(() => {
    activeReaction.value = "";
  }, 5000);
};

const symbolToShow = computed(() => {
  const [first, second] = props.participantData.identity.split(" ");

  return (first[0] + (second ? second[0] : "")).toUpperCase();
});

const raisedHandStr = computed(() => {
  const amount = props.raisedHands?.length;

  if (!amount) {
    return "";
  }
  let res = props.raisedHands[0].identity;

  if (amount > 1) {
    return `${res} (${amount})`;
  }

  return res;
});

const updateHand = (val: "handsUp" | "handsDown") => {
  isHandUp.value = val === "handsUp";
};

const updateCameraState = () => {
  const cameraPub = props.participant.getTrackPublication(Track.Source.Camera);
  const videoElm = document.getElementById(
    `video-${props.participant.identity}`
  ) as HTMLVideoElement | null;

  if (!videoElm) {
    return;
  }

  if (props.participantData.isCameraEnabled && videoElm) {
    videoElm.style.transform = "scale(-1, 1)";

    cameraPub?.videoTrack?.attach(videoElm);
  } else {
    // clear information display
    if (cameraPub?.videoTrack) {
      // detach manually whenever possible
      cameraPub.videoTrack?.detach(videoElm);
    } else {
      videoElm.src = "";
      videoElm.srcObject = null;
    }
  }
};
watch(
  () => props.participantData.isCameraEnabled,
  updateCameraState,
);
defineExpose<VideoParticipantExpose>({
  updateHand,
  setReaction,
  identity: props.participantData.identity,
});

onMounted(() => {
  updateCameraState()
});
</script>

<template>
  <div
    class="video-participant"
    :class="{ 'video-participant--speaking': participantData.isSpeaking }"
  >
    <video
      ref="videoElm"
      v-show="participantData.isCameraEnabled"
      :id="`video-${participantData.identity}`"
      class="video-participant__video"
    ></video>

    <div
      v-if="!participantData.isCameraEnabled"
      class="video-participant__placeholder"
    >
      <img
        v-if="participantData.avatarUrl"
        :src="participantData.avatarUrl"
        alt=""
      />
      <template v-else>
        {{ symbolToShow }}
      </template>
    </div>

    <div
      class="video-participant__name"
      :class="{ 'video-participant__name--move': moveName }"
    >
      {{ participantData.identity }}
    </div>

    <div
      v-if="isMini && raisedHandStr"
      class="video-participant__raised"
      :class="{ 'video-participant__raised--move': moveName }"
    >
      <hand-icon class="video-participant__raised-icon" />

      {{ raisedHandStr }}
    </div>

    <video-fade-transition>
      <hand-icon
        v-if="isHandUp"
        class="video-participant__reaction video-participant__reaction--hand"
      />
      <div v-else-if="activeReaction" class="video-participant__reaction">
        {{ activeReaction }}
      </div>
    </video-fade-transition>
  </div>
</template>

<style scoped lang="sass">
@import "@/sass/reaction-item"

.video-participant
  box-sizing: border-box
  width: 400px
  border-radius: 16px
  display: flex
  background: #2D2830
  position: relative
  justify-content: center
  align-items: center
  overflow: hidden

  &__reaction
    @include video-reaction-item
    position: absolute
    top: 12px
    left: 12px
    &--hand
      border-radius: 8px
      padding: 8px
      width: 40px
      height: 40px

  &--speaking
    &::before
      content: " "
      position: absolute
      border: 2px solid #8B15C2
      left: 0
      right: 0
      bottom: 0
      top: 0
      border-radius: 16px

    .video-participant__name
      background: #8B15C2

  &__placeholder
    border-radius: 100px
    width: 112px
    height: 112px
    display: flex
    justify-content: center
    align-items: center
    background: #EDCAF8
    color: #1D191E
    font-family: "Roboto Condensed"
    font-size: 28px
    font-style: normal
    font-weight: 400
    line-height: 28px
    overflow: hidden

    img
      width: 100%
      height: 100%


  &__name
    position: absolute
    left: 12px
    bottom: 12px
    padding: 7px 8px
    font-family: "Roboto Condensed"
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 10px
    letter-spacing: 0.28px
    color: #fff
    background: rgba(29, 25, 30, 0.60)
    border-radius: 8px
    transition: transform 0.5s ease

    &--move
      transform: translateY(-52px)

  &__raised
    display: flex
    position: absolute
    left: 12px
    bottom: 43px
    padding: 7px 8px
    font-family: "Roboto Condensed"
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 22px
    letter-spacing: 0.28px
    color: #fff
    background: rgba(29, 25, 30, 0.9)
    border-radius: 8px
    transition: transform 0.5s ease

    &--move
      transform: translateY(-52px)

    &-icon
      width: 24px
      height: 24px
      margin-right: 8px

  &__video
    width: calc(99% - 4px)
    height: calc(99% - 4px)
    overflow: hidden
    border-radius: 16px
</style>
