<script setup lang="ts">
import {
  Participant,
  RemoteParticipant,
  Room,
  RoomEvent,
  MediaDeviceFailure,
  DisconnectReason,
  ConnectionQuality,
  DataPacket_Kind,
  LocalParticipant,
  ParticipantEvent,
  TrackPublication,
  Track,
} from "livekit-client";
import { computed, provide, ref, watch } from "vue";
import VideoParticipant from "./VideoParticipant.vue";
import VideoChat from "@/components/video-conference/VideoChat.vue";
import VideoControls from "@/components/video-conference/VideoControls.vue";
import VideoViewControls from "@/components/video-conference/VideoViewControls.vue";
import VideoReaction from "@/components/video-conference/VideoReaction.vue";
import useDataStorage from "@/components/video-conference/composables/use-data-storage";
import VideoFadeTransition from "@/components/video-conference/VideoFadeTransition.vue";
import { VideoControlState } from "@/components/video-conference/types/video-controls-state";
import VideoPagination from "@/components/video-conference/VideoPagination.vue";
import { ParticipantPropsData } from "./types/video-participant-props";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const emit = defineEmits(["room-disconnected"]);

const participantsRef = ref<InstanceType<typeof VideoParticipant>[]>([]);

const route = useRoute();
const preventAdmin = route.query.nochange === "true";

const store = useStore();
const isMini = ref(true);
const isTiles = ref(false);
const isChatOpen = ref(true);
const isMouseOver = ref(false);

const videoControlsState = ref<VideoControlState>({
  isVideoEnabled: false,
  isMicEnabled: false,
  isHandsUp: false,
});

let startTime: number;
const pinnedUsers = ref<string[]>([]);
const raisedHands = ref<{ identity: string; expire_at: string }[]>([]);
const isSpectator = ref(false);

function appendLog(...args: any[]) {
  console.log(...args);
}

const participants = ref<Participant[]>([]);

const participantsSort = (a: Participant, b: Participant) => {
  const firstHasCamera = +a.isCameraEnabled;
  const secondHasCamera = +b.isCameraEnabled;

  const isFirstPinned = +pinnedUsers.value.includes(a.identity);
  const isSecondPinned = +pinnedUsers.value.includes(b.identity);

  return (
    isSecondPinned - isFirstPinned ||
    secondHasCamera - firstHasCamera ||
    a.identity.localeCompare(b.identity)
  );
};

const amountOfCurrentParticipants = computed(() => {
  if (isMini.value) {
    return 1;
  }

  if (isTiles.value) {
    return participants.value.length;
  }

  return participants.value.filter((p) =>
    pinnedUsers.value.includes(p.identity)
  ).length;
});

const maxItemsPerPage = 9;
const amountOfPages = computed(() => {
  return Math.ceil(amountOfCurrentParticipants.value / maxItemsPerPage);
});

const currentPage = ref(0);

const showPagination = computed(() => {
  return !isMini.value && amountOfCurrentParticipants.value > maxItemsPerPage;
});

const decoder = new TextDecoder();

const findParticipantComponent = (identity: string) => {
  return participantsRef.value.find((el) => el.identity === identity);
};
const participantProps = ref<Record<string, ParticipantPropsData>>({});
const { handleData, sendMessage, messages, loadChatHistory, currentRoom } =
  useDataStorage(findParticipantComponent);

provide("findParticipantComponent", findParticipantComponent);

const updateParticipant = async (participant: Participant) => {
  let avatarUrl = "";
  let isSpeaking = false;
  let isCameraEnabled = false;

  try {
    if (participant.metadata) {
      const meta = JSON.parse(participant.metadata);
      if (meta.avatar_url) {
        avatarUrl = meta.avatar_url;
      }
    }
  } catch (e) {
    console.error(e);
  }

  const micPub = participant.getTrackPublication(Track.Source.Microphone);

  isSpeaking = participant.isSpeaking;

  isCameraEnabled = participant.isCameraEnabled;
  updateParticipantToRender();

  participantProps.value[participant.identity] = {
    avatarUrl,
    isSpeaking,
    isCameraEnabled,
    identity: participant.identity,
  };

  const audioElm = document.getElementById(
    `audio-${participant.identity}`
  ) as HTMLAudioElement | null;

  const micEnabled = micPub && micPub.isSubscribed && !micPub.isMuted;
  if (audioElm) {
    if (micEnabled) {
      if (!(participant instanceof LocalParticipant)) {
        audioElm.onloadeddata = () => {
          if (
            participant.joinedAt &&
            participant.joinedAt.getTime() < startTime
          ) {
            const fromJoin = Date.now() - startTime;
            console.log(
              `RemoteAudioTrack ${micPub?.trackSid} played ${fromJoin}ms from start`
            );
          }
        };
        micPub?.audioTrack?.attach(audioElm);
      }
    }
  }
};

function participantConnected(participant: Participant) {
  appendLog(
    "participant",
    participant.identity,
    "connected meta adata: ",
    participant.metadata
  );

  if (!participant.metadata) {
    return;
  }

  try {
    const meta = JSON.parse(participant.metadata);
    if (meta.role === "spectator") {
      return;
    }
  } catch (e) {
    console.error(e);
  }
  participantProps.value[participant.identity] = {
    avatarUrl: "",
    isSpeaking: false,
    isCameraEnabled: false,
    identity: participant.identity,
  };
  const idx = participants.value.findIndex(
    (p) => p.identity === participant.identity
  );

  if (idx !== -1) {
    participants.value[idx] = participant;
  } else {
    participants.value.push(participant);
  }

  participant
    .on(ParticipantEvent.LocalTrackPublished, (pub: TrackPublication) => {
      console.log("track was published", pub.trackSid, participant.identity);
      updateParticipant(participant);
    })
    .on(ParticipantEvent.TrackMuted, (pub: TrackPublication) => {
      console.log("track was muted", pub.trackSid, participant.identity);
      updateParticipant(participant);
    })
    .on(ParticipantEvent.TrackUnmuted, (pub: TrackPublication) => {
      console.log("track was unmuted", pub.trackSid, participant.identity);
      updateParticipant(participant);
    })
    .on(ParticipantEvent.IsSpeakingChanged, () => {
      updateParticipant(participant);
    })
    .on(ParticipantEvent.ConnectionQualityChanged, () => {
      updateParticipant(participant);
    });

  updateParticipantToRender();
}

function participantDisconnected(participant: RemoteParticipant) {
  appendLog("participant", participant.sid, "disconnected");
  participants.value = participants.value.filter(
    (el) => el.identity !== participant.identity
  );

  updateParticipantToRender();
}

function handleRoomDisconnect(reason?: DisconnectReason) {
  emit("room-disconnected");

  if (!currentRoom) return;
  appendLog("disconnected from room", { reason });

  participants.value = [];
  updateParticipantToRender();
}

const handleHands = () => {
  participantsRef.value.forEach((el) => {
    el.updateHand(
      raisedHands.value.some((r) => r.identity === el.identity)
        ? "handsUp"
        : "handsDown"
    );
  });

  videoControlsState.value.isHandsUp = raisedHands.value.some(
    (r) => r.identity === currentRoom?.localParticipant.identity
  );
};

const lastActiveParticipant = ref<Participant | null>(null);

const participantsToRender = ref<Participant[]>([]);

const updateParticipantToRender = () => {
  handleHands();

  const allValuesToUse = [...participants.value];
  allValuesToUse.sort(participantsSort);

  // В мини рисуем говорящего или последнего говорившего спикера
  if (isMini.value) {
    if (lastActiveParticipant.value) {
      participantsToRender.value = [lastActiveParticipant.value as Participant];
      return;
    }

    if (allValuesToUse.length > 0) {
      const res = allValuesToUse.find((p) => !p.isLocal);

      if (!res) {
        participantsToRender.value = [allValuesToUse[0] as Participant];
        return;
      }

      participantsToRender.value = [res];
      return;
    }
  }

  if (!isTiles.value) {
    const pinnedParticipants = allValuesToUse.filter((el) =>
      pinnedUsers.value.includes(el.identity)
    );

    if (pinnedParticipants.length === 0) {
      if (lastActiveParticipant.value) {
        participantsToRender.value = [
          lastActiveParticipant.value as Participant,
        ];
        return;
      }

      if (allValuesToUse.length > 0) {
        const res = allValuesToUse.find((p) => !p.isLocal);

        if (!res) {
          participantsToRender.value = [allValuesToUse[0] as Participant];
          return;
        }

        participantsToRender.value = [res];
        return;
      }

      return;
    } else {
      participantsToRender.value = pinnedParticipants;
      return;
    }
  }

  if (currentPage.value + maxItemsPerPage > allValuesToUse.length) {
    participantsToRender.value = allValuesToUse.slice(
      currentPage.value * maxItemsPerPage,
      allValuesToUse.length
    );

    return;
  }

  participantsToRender.value = allValuesToUse.slice(
    currentPage.value * maxItemsPerPage,
    currentPage.value * maxItemsPerPage + maxItemsPerPage
  );
};

const handleRoomMetaData = (metaData?: string) => {
  if (!metaData) {
    return;
  }

  try {
    const data = JSON.parse(metaData);

    pinnedUsers.value = data.pinned_users || [];
    raisedHands.value = data.raised_hand || [];
    updateParticipantToRender();
  } catch (e) {
    console.error(e);
  }
};

const connectToRoom = async (): Promise<Room | undefined> => {
  if (!currentRoom) {
    return;
  }

  startTime = Date.now();
  const prewarmTime = Date.now() - startTime;

  appendLog(`prewarmed connection in ${prewarmTime}ms`);

  currentRoom
    .on(RoomEvent.Connected, (...args) => console.log(...args))
    .on(RoomEvent.RoomMetadataChanged, (d) => handleRoomMetaData(d))
    .on(RoomEvent.ActiveSpeakersChanged, (p) => {
      if (!p.length) {
        return;
      }

      lastActiveParticipant.value = p[0];
      updateParticipantToRender();
    })
    .on(RoomEvent.ParticipantConnected, participantConnected)
    .on(RoomEvent.ParticipantDisconnected, participantDisconnected)
    .on(
      RoomEvent.DataReceived,
      (
        msg: Uint8Array,
        participant?: RemoteParticipant,
        kind?: DataPacket_Kind,
        topic?: string
      ) => {
        if (topic === "view") {
          if (preventAdmin) {
            return;
          }

          const type = decoder.decode(msg);

          if (type === "mini") {
            isMini.value = true;
          } else if (type === "speaker") {
            isTiles.value = false;
            isMini.value = false;
          } else if (type === "grid") {
            isTiles.value = true;
            isMini.value = false;
          }

          return;
        }

        handleData(msg, participant, kind, topic);
      }
    )
    .on(RoomEvent.Disconnected, handleRoomDisconnect)
    .on(RoomEvent.Reconnecting, () => appendLog("Reconnecting to room"))
    .on(RoomEvent.Reconnected, async () => {
      appendLog(
        "Successfully reconnected. server",
        await currentRoom.engine.getConnectedServerAddress()
      );
    })
    .on(RoomEvent.MediaDevicesError, (e: Error) => {
      const failure = MediaDeviceFailure.getFailure(e);
      alert("media device failure " + failure);
      appendLog("media device failure", failure);
    })
    .on(
      RoomEvent.ConnectionQualityChanged,
      (quality: ConnectionQuality, participant?: Participant) => {
        appendLog("connection quality changed", participant?.identity, quality);
      }
    )
    .on(RoomEvent.TrackSubscribed, (track, pub, participant) => {
      appendLog("subscribed to track", pub.trackSid, participant.identity);

      updateParticipant(participant);

      updateParticipantToRender();
    })
    .on(RoomEvent.TrackMuted, (track, participant) => {
      if (!(participant instanceof LocalParticipant)) {
        return;
      }

      if (track.kind === "video") {
        videoControlsState.value.isVideoEnabled = false;
      } else if (track.kind === "audio") {
        videoControlsState.value.isMicEnabled = false;
      }

      updateParticipantToRender();
    })
    .on(RoomEvent.TrackUnsubscribed, (_, pub, participant) => {
      appendLog("unsubscribed from track", pub.trackSid);
      const c = participants.value.findIndex(
        (el) => el.identity === participant.identity
      );

      if (c !== -1) {
        participants.value.splice(c, 1, participant);
      }

      updateParticipantToRender();
    })
    .on(RoomEvent.SignalConnected, async () => {
      const signalConnectionTime = Date.now() - startTime;
      appendLog(`signal connection established in ${signalConnectionTime}ms`);
    })
    .on(RoomEvent.TrackStreamStateChanged, (pub, streamState, participant) => {
      participantProps.value[participant.identity].isCameraEnabled =
        participant.isCameraEnabled;

      appendLog(
        `stream state changed for ${pub.trackSid} (${
          participant.identity
        }) to ${streamState.toString()}`
      );
    });

  try {
    handleRoomMetaData(currentRoom.metadata);

    const elapsed = Date.now() - startTime;

    appendLog(
      `successfully connected to ${currentRoom.name} in ${Math.round(
        elapsed
      )}ms`,
      await currentRoom.engine.getConnectedServerAddress()
    );

    if (currentRoom.localParticipant.metadata) {
      const meta = JSON.parse(currentRoom.localParticipant.metadata);
      isSpectator.value = meta.role === "spectator";
    }
  } catch (error: any) {
    let message: any = error;

    if (error.message) {
      message = error.message;
    }

    appendLog("could not connect:", message);
    return;
  }

  loadChatHistory();

  currentRoom.remoteParticipants.forEach((participant) => {
    participantConnected(participant);
  });
  participantConnected(currentRoom.localParticipant);

  return currentRoom;
};

const compStyle = computed(() => {
  const len = participantsToRender.value.length;

  if (len < 3) {
    return `grid-template-rows: 100%;`;
  }

  if (len < 5) return `grid-template-rows: 49% 49%;`;

  return `grid-template-rows: 32% 32% 32%;`;
});

watch([isMini, isTiles, currentPage], () => {
  updateParticipantToRender();
});

watch(
  () => currentRoom,
  () => {
    if (currentRoom) {
      connectToRoom();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => store.getters.active,
  (val: string) => {
    if (val === "3_mood_video") {
      isMini.value = true;
    }
  }
);
</script>

<template>
  <div
    class="video-section"
    :class="{
      'video-section--mini': isMini,
      'video-section--chat': isChatOpen,
    }"
  >
    <div
      class="video-section__container"
      @mouseenter="isMouseOver = true"
      @mouseleave="isMouseOver = false"
    >
      <video-fade-transition>
        <video-view-controls
          v-if="isMouseOver || !isMini"
          class="video-section__view"
          @set-mini="isMini = !isMini"
          @set-tiles="isTiles = !isTiles"
          :is-tiles="isTiles"
          :is-mini="isMini"
        />
      </video-fade-transition>

      <video-fade-transition>
        <video-controls
          v-show="(isMouseOver || !isMini) && !isSpectator"
          class="video-section__controls"
          :is-small="isMini"
          v-model="videoControlsState"
        />
      </video-fade-transition>
      <video-fade-transition>
        <video-reaction
          v-show="(isMouseOver || !isMini) && !isSpectator"
          class="video-section__reactions"
          @setReaction="sendMessage($event, 'reaction')"
        />
      </video-fade-transition>

      <div
        id="participants-area"
        class="video-section__participants"
        :class="{
          'video-section__participants--small':
            participantsToRender.length > 1 && participantsToRender.length < 6,
          'video-section__participants--big': participantsToRender.length > 5,
        }"
        :style="compStyle"
      >
        <video-participant
          ref="participantsRef"
          class="video-section__participants-item"
          v-for="value in participantsToRender"
          :key="value.identity"
          :participant="value"
          :participant-data="participantProps[value.identity]"
          :move-name="isMouseOver && isMini && !isSpectator"
          :is-mini="isMini"
          :raised-hands="raisedHands"
        />
      </div>

      <video-pagination
        v-if="showPagination"
        :amount-of-pages="amountOfPages"
        v-model:current-page="currentPage"
      />
    </div>

    <video-chat
      class="video-section__chat"
      :is-mini="isMini"
      :is-chat-open="isChatOpen"
      :is-spectator="isSpectator"
      @set-chat-open="isChatOpen = $event"
      @send-message="sendMessage($event, 'chat')"
      @send-local-message="sendMessage($event, 'chat', true)"
      :messages="messages"
    />

    <audio
      v-for="p in participants"
      :id="`audio-${p.identity}`"
      :key="p.identity"
    ></audio>
  </div>
</template>

<style scoped lang="sass">
@import "@/sass/functions"
.video-section
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 100
  display: flex

  &__reactions
    position: absolute
    right: 24px
    bottom: 24px
    z-index: 1

  &__container
    position: relative
    width: 100%
    height: 100%

  &__chat
    width: vw(424px)

  &__participants
    background: linear-gradient(108deg, #2F2B65 -4.29%, #801A74 45.18%, #C381B6 92.34%)
    width: 100%
    height: 100%
    padding: 24px 24px 130px
    display: grid
    grid-gap: 16px

    &--small
      grid-template-columns: repeat(4, 1fr)

      .video-section__participants-item
        grid-column: span 2

        &:last-child:nth-child(2n - 1)
          grid-column-end: -2

        &:nth-last-child(2):nth-child(2n + 1)
          grid-column-end: 3

        &:last-child:nth-child(2n - 2)
          grid-column-end: 5


    &--big
      grid-template-columns: repeat(6, 1fr)

      .video-section__participants-item
        grid-column: span 2

        &:last-child:nth-child(3n - 1)
          grid-column-end: -2

        &:nth-last-child(2):nth-child(3n + 1)
          grid-column-end: 4

        &:last-child:nth-child(3n - 2)
          grid-column-end: 5

    &-item
      width: auto
  &__controls
    position: absolute
    left: 24px
    bottom: 24px
    z-index: 111

  &__view
    position: absolute
    top: 24px
    right: 24px
    z-index: 1

  &--mini
    left: unset
    top: 24px
    flex-direction: column
    right: 24px
    bottom: calc(100vh - 24px - 244px - 40px)
    border-radius: 16px
    box-shadow: 0px 20px 36px 0px rgba(0, 0, 0, 0.25)
    overflow: hidden
    height: auto
    max-height: calc(100% - 48px)
    display: block
    transition: bottom 0.3s ease
    background: #1D191E

    .video-section
      &__participants
        padding: 0
        &-item
          border-bottom-left-radius: 0
          border-bottom-right-radius: 0

      &__controls
        left: 16px
        bottom: 16px

      &__reactions
        right: 16px
        bottom: 16px

      &__view
        top: 16px
        right: 16px

    .video-section__container
      height: 240px
      width: vw(424px)

    .video-section__chat
      height: 40px

    &.video-section--chat
      bottom: 24px
      // width: auto
      width: vw(424px)

    &.video-section--chat .video-section__container
      height: 240px

    &.video-section--chat .video-section__chat
      height: calc(100% - 244px)
</style>
