<template>
  <ChapterView>
    <template v-slot:title><Chapter_1_Title /></template>
    <template v-slot:avatar><img src="@/assets/img/avatar-13.png" alt=""></template>
    <template v-slot:name>Волошин <br>Сергей Владимирович</template>
    <template v-slot:regalia>к.м.н., ГБУЗ ЛОКБ, Санкт-Петербург</template>
  </ChapterView>
</template>

<script>
import ChapterView from '@/components/ChapterView'
import Chapter_1_Title from '@/components/Chapter_1_Title.vue'

export default {
  name: 'Chapter_1',
  components: {
    Chapter_1_Title,
    ChapterView,
  },
}
</script>
